<template>
  <section class="form form--narrow">
    <h2 class="form__heading">Log in</h2>
    <!-- LinkedIn disabled because broken. 2024-02-23
    <form
      class="form__group form__group--center form__button-group form__button-group--full-width-with-margin"
      method="post"
      :action="externalLoginCallbackUrl"
    >
      <input type="hidden" name="returnUrl" :value="mode == 'popup' ? $route.fullPath : $router.returnUrl" />
      <fs-button-main
        :long="true"
        size="large"
        type="submit"
        name="provider"
        value="LinkedIn"
        title="Log in using your LinkedIn account"
      >
        <FsButtonIcon :small="true" :icon="svg.linkedIn" />Log in with LinkedIn
      </fs-button-main>
    </form>
    -->
    <form action="#" @submit.prevent="logIn()">
      <!--
      <div class="form__group form__group--center">
        <span class="form__note-header">Or</span>
      </div>
      -->
      <div class="form__group">
        <Field
          type="email"
          name="email"
          ref="email"
          label="E-mail"
          v-model.trim="loginData.email"
          :validation="emailValidation"
        />
        <Password
          name="password"
          ref="password"
          label="Password"
          v-model.trim="loginData.password"
          :validation="passwordValidation"
          :toggled.sync="_toggled"
        />
        <Field
          v-if="needMfa"
          type="text"
          name="twoFactorCode"
          ref="twoFactorCode"
          label="2FA code"
          v-model.trim="loginData.twoFactorCode"
          :validation="emailValidation"
        />
      </div>
      <div class="form__group form__group--message" v-if="loginErrors.unknownError">
        <p class="form__message form__message--error">
          <span class="form__message-icon" v-html="svg.error"></span>
          Unknown error, please try again!
        </p>
      </div>
      <div class="form__group form__group--message" v-if="needMfa">
        <p class="form__message">Need to provide 2FA code. Please open up your authenticator app to find the code.</p>
      </div>
      <div class="form__button-group form__button-group--right">
        <router-link
          class="link link--button form__link"
          :event="mode == 'popup' ? '' : 'click'"
          @click.native="() => (mode == 'popup' ? $parent.open('forgotPassword') : '')"
          :to="{ name: 'forgotPassword' }"
          >Forgot password?</router-link
        >
        <fs-button-main :loading="loading">Log in</fs-button-main>
      </div>
      <div class="form__group form__group--new-section form__group--center">
        <strong class="form__subtitle">Don't have an account?</strong>
      </div>
      <div class="form__group form__group--center form__button-group--full-width-with-margin">
        <fs-button-main
          styling="outlined"
          size="large"
          :long="true"
          :event="mode == 'popup' ? '' : 'click'"
          @click.native="() => (mode == 'popup' ? $parent.open('signUp') : '')"
          :to="signUpLocation"
        >
          Register account
        </fs-button-main>
      </div>
    </form>
  </section>
</template>

<script>
import Field from './../components/field.vue';
import FsButtonMain from '@components/buttons/fs-button-main.vue';
import FsButtonIcon from 'apps/public/pages/components/fs-button-icon.vue';
import Password from './../components/password-field.vue';

import linkedIn from '../../img/in-icon.svg';
import error from '../../img/baseline-error-24px.svg';

import twoWayProperty from 'apps/public/lib/twoWayProperty';

export default {
  load({ store, route }) {
    store.head.title = `FindSourcing – Login`;
  },
  components: { Field, FsButtonMain, FsButtonIcon, Password },
  props: ['mode', 'email', 'password', 'toggled'],
  data() {
    return {
      loading: false,
      svg: {
        linkedIn,
        error,
      },
      loginData: {
        email: this.email || '',
        password: this.password || '',
        twoFactorCode: '',
        rememberMe: true,
        showPassword: false,
      },
      loginErrors: {
        unknownError: false,
      },
      needMfa: false,
    };
  },
  computed: {
    _toggled: twoWayProperty('toggled'),
    externalLoginCallbackUrl() {
      let companyGuid = this.$route.query.companyGuid;

      return '/api/account/externalLogin' + (companyGuid ? '?companyGuid=' + companyGuid : '');
    },
    signUpLocation() {
      let location = { name: 'signUp' };
      let companyGuid = this.$route.query.companyGuid;

      if (companyGuid) location.query = { companyGuid };

      return location;
    },
  },
  methods: {
    emailValidation(val) {
      if (val === '') return 'E-mail is required';
    },
    passwordValidation(val) {
      if (val === '') return 'Password is required';
    },
    twoFactorCodeValidation(val) {
      if (val === '') return '2FA code is required';
    },
    logIn() {
      this.loading = true;
      this.loginErrors.unknownError = false;

      Promise.all([this.$refs.email.validates(), this.$refs.password.validates()])
        .then((validations) => {
          if (validations.filter((validation) => !validation).length) {
            this.loading = false;
            return;
          }

          return this.$store.user
            .logIn(this.loginData, this.$route.query.companyGuid)
            .then((status) => {
              if (status === 4) {
                this.loading = false;
                this.$refs.password.addError('Wrong password!');
                return;
              }

              if (status === 5) {
                this.loading = false;
                this.$refs.email.addError('No such e-mail exist in our database!');
                return;
              }

              if (status === 6) {
                this.loading = false;
                this.$refs.twoFactorCode.addError('2FA not valid!');
                return;
              }

              if (status === 2) {
                this.loading = false;
                this.needMfa = true;
                return;
              }

              if (this.mode === 'popup') {
                this.$parent.close('logIn');
              } else {
                this.$router.push(this.$router.returnUrl);
              }

              this.$notify({
                type: 'success',
                title: 'You are successfully logged in',
              });
            })
            .catch((error) => {
              throw new Error(error);
            });
        })
        .catch((error) => {
          this.loginErrors.unknownError = true;
          this.loading = false;
        });
    },
    decline() {
      if (this.$route.query.declined) {
        this.$notify({
          type: 'error',
          title: 'Permission declined',
        });
      }
    },
  },
  watch: {
    'loginData.email': function () {
      this.$emit('update:email', this.loginData.email);
    },
    'loginData.password': function () {
      this.$emit('update:password', this.loginData.password);
    },
  },
  mounted() {
    window.addEventListener('load', this.decline);

    // Temp hack for main padding, only when in page mode (not popup)
    try {
      if (!this.$parent.$parent) document.getElementsByClassName('main')[0].classList.add('main--padding');
    } catch (e) {}
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.decline);

    // Temp hack for main padding, only when in page mode (not popup)
    try {
      if (!this.$parent.$parent) document.getElementsByClassName('main')[0].classList.remove('main--padding');
    } catch (e) {}
  },
};
</script>
